import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AgentPage = () => {
  return (
    <Layout>
      <SEO
        title="Agent Login - National Partners"
        description="National Partners was founded by a team of highly qualified and experienced finance executives who are dedicated to providing the highest standards in the premium finance industry."
      />
      <div className="page-headline">
        <div className="hero-headline agent-page">
          <div className="container">
            <div className="section-heading text-center"></div>
          </div>
        </div>
      </div>
      <div className="page-content agent-content">
        <section className="agent-body pt-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <h4 className="f-700 text-white pb-0 mb-4 ml-5">
                  AGENTS LOGIN
                </h4>
                <form className="agent-form">
                  <div className="form-group">
                    <label>Username:</label>
                    <input
                      className="form-control agent-input"
                      type="text"
                      name="Username"
                    />
                  </div>
                  <div className="form-group mb-4">
                    <label>Password:</label>
                    <input
                      className="form-control agent-input"
                      type="password"
                      name="Password"
                    />
                  </div>
                  <div className="form-group text-left d-flex align-items-center justify-content-between flex-wrap">
                    <button className="btn btn-primary login-cta" type="submit">
                      SEND
                    </button>
                    <div className="forgot-account">
                      <p className="section-p f-700 mb-0">
                        Forgot username or password?
                      </p>
                      <p className="section-p f-700 mb-0">
                        Sign Up Free |{" "}
                        <a href="/" className="text-gold">
                          Start here.
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="agent-content-right mt-5">
                  <h4 className="f-700 text-white">
                    National Partners offers a comprehensive and reliable
                    partnership for agents.
                  </h4>
                  <h5 className="f-300 text-white my-0 py-0">
                    With our efficient technology solutions and a dedicated
                    service team, you can rest assured that you can formulate
                    the best course of action for your clients and your
                    business.
                  </h5>
                  <h5 className="f-300 text-white mb-4">
                    See what our Premium Finance Program for agents has to
                    offer.
                  </h5>
                  <Link to="/" className="cta-gold-gradient">
                    LEARN MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AgentPage
